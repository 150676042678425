export const environment = {
  production: true,
  API_URL: "https://back-qa.cintuz.com/api",
  recaptcha: {
    siteKey: "6Lc2hAooAAAAAELa04wCdTLwsP_2OfRWm3s0tTAs"
  },
  VAPID_KEY: "BHToxn_wv8FhS0rwa39VzmAqJXfn0ScFVlo0JsuJo4dqU2I1Eh-pAj5PoEK65FGwnPMoo5qlA9Y-QZpHxzt_Phg",
  LINK_WEB_CINTUZ: 'https://web-qa.cintuz.com/#/home',
  GOOGLE_MAP_API_KEY: "AIzaSyCbdQApB2Yu8YTYToQxLiy5iQbasvtUtJE",
  WAPP_LINK: "https://wa.me/584122484475",
  VERSION_APP: "1.0"
};
